import styled from 'styled-components';

export const MusicSection = styled.section`
	${tw`pt-24`};
	.title-big {
		${tw`text-center sm:text-128`};
		top: -0.55em;
		margin-bottom: -0.35em;
	}
	.text-left {
		${tw`text-left`};
	}
	p,
	h2 {
		${tw`m-auto`};
		max-width: 600px;
	}
	.iframe-wrapper {
		${tw`m-auto mb-5`};
		max-width: 600px;
	}
`;
