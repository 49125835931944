import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';

import Template from './../../layouts/Template';
import { MusicSection } from './_Styles';

const Music = ({ data }) => (
	<Template
		title={`Music | ${data.site.siteMetadata.title}`}
		desc="Sophia Zey Music - Sophia currently teaches a selective private studio of voice and piano lessons in Oconomowoc, WI and the Milwaukee metropolitan area."
	>
		<MusicSection>
			<div className="wrapper">
				<div className="container">
					<div className="box">
						<header className="title-big">
							<h1>Music</h1>
						</header>
						<section className="text-left">
							<h2>Music Bio</h2>
							<p>
								Sophia Zey is a pianist and soprano who lives in Oconomowoc, WI. She has subbed numerous times as an opera coach
								and pianist for the Lyric Opera of Kansas City , and performed at LOKC special events with several young artists.
								She attended Aspen Music Festival as a Fellowship Opera Coach for the Aspen Opera Center, where she was appointed
								to chorus master and assistant coach of Berlioz’ opera Béatrice et Bénédict. She holds a B.A. Music Performance
								from Luther College, and a M.M. Vocal Performance from the University of Kansas City-Missouri’s Conservatory of
								Music and Dance.
							</p>
							<p>
								Sophia has also performed on stage as a member of the back-up chorus for Barry Manilow and The Rolling Stones and
								led a new musical reading as the music director.
							</p>
							<p>
								She also enjoys web design and development, and currently teaches voice and piano in her spare time. She currently
								has limited openings - please <Link to="/contact">Contact</Link> for more information and current availability.
							</p>
							<h2>Recordings</h2>
							<div className="iframe-wrapper">
								<iframe
									width="560"
									height="315"
									src="https://www.youtube.com/embed/yohWcGZhd9g?rel=0&modestbranding=1"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							</div>
							<div className="iframe-wrapper">
								<iframe
									width="560"
									height="315"
									src="https://www.youtube.com/embed/TDdKQUItUdY"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							</div>
						</section>
					</div>
				</div>
			</div>
		</MusicSection>
	</Template>
);

export default Music;

export const pageQuery = graphql`
	query MusicQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
